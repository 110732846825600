import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import FooterComponent from "../Landing/FooterComponent";
import LandingHeader from "../Landing/LandingHeader";
import BetaTestingPopup from "../Popups/BetaTestingPopup";
import CryptoChampTopBar from "../../Pages/NewLandingPage/Topbar";
import LandingPageSidebar from "../../Pages/NewLandingPage/LandingPageSideBar";
import { DrawerContext } from "../../context/DrawerContext";
import { Sticky, StickyContainer } from "react-sticky";

const LandingLayout = () => {
  const location = useLocation();

  console.log(location?.pathname, "currentlocation");
  // const [collapsed, setCollapsed] = useState(false);

  // const handleCollapsed = () => {
  //   setCollapsed(!collapsed);
  // };
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const { landingIsCollapsed, setLandingIsCollapsed } =
    useContext(DrawerContext);

  console.log("landingIsCollapsed", landingIsCollapsed);
  return (
    <div>
      <Grid className="mainPage">
        {/* <Grid container sx={{ width: "100%" }}> */}

        {/* {location?.pathname !== "/" && (
          <nav style={{ width: "100%" }}>
            <LandingHeader fromOtherPage={true} />
          </nav>
        )} */}
        <CryptoChampTopBar />

        {/*  */}

        {/* <Box
          display="grid"
          gridTemplateColumns={
            isMatch ? "100%" : landingIsCollapsed ? "6% 95%" : "18% 82%"
          }
          width="100%"
          sx={{
            mt: {
              xs: "4.5rem",
              md: "6.1rem",
            },
            overflow: "hidden",
          }}
        > */}
        <StickyContainer
          style={{
            display: "grid",
            gridTemplateColumns: isMatch
              ? "100%"
              : landingIsCollapsed
              ? "6% 95%"
              : "18.5% 82.5%",
            width: "100%",
            // marginTop: isMatch ? "4.5rem" : "6.1rem",
          }}
        >
          {/* Sidebar */}

          <Sticky>
            {(props) => (
              <Box
                // className="mainPage"
                sx={{
                  ...props.style,
                  marginTop: isMatch ? "4.5rem" : "6.1rem",
                  maxHeight: 500,
                  // width: landingIsCollapsed ? "6%" : "18.5%",
                  // width: "100%",
                  minWidth: landingIsCollapsed ? "6%" : "18.5%",
                  // width: "100%",
                  overflowY: "auto",
                  transition: "all 0.5s tween",
                }}
              >
                <LandingPageSidebar />
              </Box>
            )}
          </Sticky>
          {/* <Sticky>
            {(props) => (
              <div
                // className="mainPage"
                style={{
                  ...props.style,
                  marginTop: isMatch ? "4.5rem" : "6.1rem",
                  maxHeight: 500,
                  // width: landingIsCollapsed ? "6%" : "18.5%",
                  overflowY: "auto",
                  transition: "all 0.5s tween",
                }}
              >
                <LandingPageSidebar />
              </div>
            )}
          </Sticky> */}

          {/* Main Content */}
          {/* <Box display="flex" flexDirection="column" width="100%" height="100%"> */}
          <div
            style={{
              position: "relative",
              marginTop: isMatch ? "4.5rem" : "6.1rem",
              transition: "all 0.5s tween",
              width: "100%",
            }}
          >
            <main style={{ flexGrow: 1 }}>
              <Outlet />
            </main>
            <footer style={{ flexShrink: 0 }}>
              <FooterComponent />
            </footer>
          </div>
          {/* </Box> */}
        </StickyContainer>

        {/* </Grid> */}
      </Grid>
      <BetaTestingPopup />
    </div>
  );
};

export default LandingLayout;
