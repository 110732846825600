import {
  Avatar,
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { DrawerContext } from "../../context/DrawerContext";
import { RxDiscordLogo } from "react-icons/rx";
import { PiTelegramLogoLight } from "react-icons/pi";
import { RiTwitterXLine } from "react-icons/ri";
import { BiBookAlt } from "react-icons/bi";
import { commonData } from "../../constants/common";

const CryptoChampTopBar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const { landingIsCollapsed, setLandingIsCollapsed } =
    useContext(DrawerContext);

  const handleCollapsed = () => {
    setLandingIsCollapsed(!landingIsCollapsed);
  };

  return (
    <Box
      position="fixed"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: "100%",
        paddingX: "1rem",
        paddingY: "1rem",
        background: "#0f103c",
        boxSizing: "border-box",
      }}
      zIndex={15}
    >
      {/* First flex */}
      <Box display="flex" justifyContent="center" gap={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1.2,
          }}
        >
          <div onClick={() => handleCollapsed()}>
            <img
              style={{
                width: isMatch ? "1.5rem" : "2rem",
                height: isMatch ? "1.5rem" : "2rem",
              }}
              src="/Images/landingPage/menuIcon.png"
              alt=""
            />
          </div>

          <Link
            style={{
              display: "block",
            }}
            to={"/"}
          >
            <img
              style={{
                width: isMatch ? "80px" : "160px",
                // marginTop: "25px",
                // height: "73.87px",
                cursor: "pointer",
              }}
              src="/Images/chmaps-logo.png"
              alt=""
              onClick={() => {}}
            />
          </Link>
        </Box>

        {/* Champs amount */}
        {/* <Box
          sx={{
            display: isMatch ? "none" : "flex",
            background: "#0F2858",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 20,
            paddingX: "1rem",
            mx: "1rem",
            my: "0.5rem",
          }}
        >
          <img
            style={{
              width: "2rem",
              height: "2rem",
            }}
            src="/Images/chmp-white-logo.png"
            alt=""
          />
          <Typography color={"white"} sx={{ pr: 1 }}>
            1 CHAMPS =
          </Typography>
          <img
            style={{
              width: "1.5rem",
              height: "1.5rem",
            }}
            src="/Images/usdc-blue-logo.png"
            alt=""
          />
          <Typography
            color="white"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.4rem",
              ml: "0.4rem",
            }}
          >
            3346.00
            <span style={{ fontSize: 12, color: "#E2E4E9" }}>USDC</span>
          </Typography>
        </Box> */}
      </Box>

      {/* Section Flex */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1.4,
        }}
      >
        {/* ALL ICONS */}

        <div
          style={{
            display: isMatch ? "none" : "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            color: "white",
            marginRight: 10,
            // marginTop: 2,
          }}
        >
          <a
            href="https://cryptochamps-io.gitbook.io/cryptochamps.io/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              padding={0.8}
              sx={{
                borderRadius: "50px",
                border: 1,
                borderColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  color: "#11B1F4",
                  background: "transparent",
                  borderColor: "#11B1F4",
                },
                cursor: "pointer",
              }}
            >
              <BiBookAlt size={18} />
            </Box>
          </a>

          <a
            href="https://twitter.com/cryptochamps_io?s=21"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Box
              padding={0.8}
              sx={{
                borderRadius: "50px",
                border: 1,
                borderColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  color: "#11B1F4",
                  background: "transparent",
                  borderColor: "#11B1F4",
                },
                cursor: "pointer",
              }}
            >
              <RiTwitterXLine size={18} />
            </Box>
          </a>

          <a
            // href="https://discord.gg/usquyPnE"
            // href="https://discord.gg/8Pm34WbS"
            // href="https://discord.gg/5FXMgARh"
            href={commonData?.discord}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              padding={0.8}
              sx={{
                borderRadius: "50px",
                border: 1,
                borderColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  color: "#11B1F4",
                  background: "transparent",
                  borderColor: "#11B1F4",
                },
                cursor: "pointer",
              }}
            >
              <RxDiscordLogo size={18} />
            </Box>
          </a>

          <a
            href="https://t.me/CryptoChampsx"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              padding={0.8}
              sx={{
                borderRadius: "50px",
                border: 1,
                borderColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  color: "#11B1F4",
                  background: "transparent",
                  borderColor: "#11B1F4",
                },
                cursor: "pointer",
              }}
            >
              <PiTelegramLogoLight size={18} />
            </Box>
          </a>

          {/* <Avatar
            sx={{
              background: "#11B1F426",
            }}
          >
            <img
              style={{
                width: "1rem",
                height: "1rem",
              }}
              src="/Images/landingPage/landingSearch.png"
              alt=""
            />
          </Avatar>

          <Divider
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "#6E7A91",
              width: "1px",
            }}
          />

          <Avatar
            sx={{
              background: "#11B1F426",
            }}
          >
            <img
              style={{
                width: "1.5rem",
                height: "1.5rem",
              }}
              src="/Images/landingPage/us_icon.png"
              alt=""
            />
          </Avatar>

          <Avatar
            sx={{
              background: "#11B1F426",
            }}
          >
            <img
              style={{
                width: "1.5rem",
                height: "1.5rem",
              }}
              src="/Images/landingPage/landingSettings.png"
              alt=""
            />
          </Avatar> */}
        </div>

        {/* LOGIN */}
        <Button
          sx={{
            paddingX: {
              xs: "1rem",
              md: "2rem",
            },
            paddingY: {
              xs: "0.3rem",
              md: "0.5rem",
            },
            fontSize: {
              xs: 10,
              md: 14,
            },
            color: "white",
            fontWeight: 500,
            textTransform: "none",
            fontFamily: "Space Grotesk",
            alignSelf: "start",
            borderRadius: "64px",
            background:
              "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
            "&:hover": {
              backgroundColor: " #10ADEF95",
            },
          }}
          variant="contained"
          onClick={() => navigate("/login")}
        >
          {"Login"}
        </Button>

        {/* SIGNUP */}
        <Button
          sx={{
            // width: "126px",
            // height: "51px",
            paddingX: {
              xs: "1rem",
              md: "2rem",
            },
            paddingY: {
              xs: "0.3rem",
              md: "0.5rem",
            },
            fontSize: {
              xs: 10,
              md: 14,
            },
            color: "white",
            fontWeight: 500,
            textTransform: "none",
            fontFamily: "Space Grotesk",
            alignSelf: "start",
            borderRadius: "64px",
            background:
              "linear-gradient(109.59deg, #E1104C -32.74%, #A0002E 127.35%)",
            "&:hover": {
              backgroundColor: " #10ADEF95",
            },
          }}
          variant="contained"
          onClick={() => navigate("/signup")}
        >
          {"Signup"}
        </Button>
      </Box>
    </Box>
  );
};

export default CryptoChampTopBar;
