import { createContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getAllNotifications } from "../Services/Service";
import { useLocation } from "react-router-dom";

export const DrawerContext = createContext("");

const DrawerProvider = (props) => {
  const location = useLocation();

  const [openLiveChat, setOpenLiveChat] = useState(false);
  const [isHomePage, setIsHomePage] = useState(false);

  const [landingIsCollapsed, setLandingIsCollapsed] = useState(false);

  const handleLiveChatOpen = () => {
    if (location?.pathname === "/home") {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
    setOpenLiveChat(!openLiveChat);
    console.log(openLiveChat);
  };

  // useEffect to handle location changes
  useEffect(() => {
    setOpenLiveChat(false);
  }, [location]);

  return (
    <DrawerContext.Provider
      value={{
        openLiveChat,
        handleLiveChatOpen,
        setOpenLiveChat,
        isHomePage,
        landingIsCollapsed,
        setLandingIsCollapsed,
      }}
    >
      {props.children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
