//

import {
  Box,
  Icon,
  List,
  Typography,
  Grow,
  Collapse,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { NavLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { GoHomeFill } from "react-icons/go";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaArrowTrendDown } from "react-icons/fa6";
import { PiGameControllerBold } from "react-icons/pi";
import { fill } from "lodash";
import { motion } from "framer-motion";
import NavBarDrawer from "./Drawer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DrawerContext } from "../../context/DrawerContext";

const menus = [
  {
    link: "/how-to-play",
    label: "How to Play",
  },
  {
    link: "/about-us",
    label: "About Us",
  },
  {
    link: "/",
    label: "Crypto Games",
    toggle: true,
    children: [
      {
        link: "/landing/biggestwinner",
        label: "Biggest Winner",
      },
      {
        link: "/landing/biggestloser",
        label: "Biggest Loser",
      },
    ],
  },
  {
    link: "/",
    label: "Stocks Games",
    toggle: true,
    children: [
      {
        link: "/landing/biggestwinner",
        label: "Biggest Winner",
      },
      {
        link: "/landing/biggestloser",
        label: "Biggest Loser",
      },
    ],
  },
  {
    link: "/roadmap",
    label: "Roadmap",
  },
  {
    link: "/signup",
    label: "Become a CHAMP",
  },
];

const getIcons = (index, collapsed) => {
  switch (index) {
    case 0:
      return (
        <img
          style={{
            width: "1rem",
            height: "1.4rem",
            objectFit: "contain",
            // filter: "grayscale(1) brightness(0.2) invert(1)",
          }}
          src="/Images/landingPage/How-to-play.png"
          alt=""
        />
      );
    case 1:
      return (
        <img
          style={{
            width: "1.2rem",
            height: "1.4rem",
            objectFit: "cover",
            // filter: "grayscale(1) brightness(0.2) invert(1)",
          }}
          src="/Images/landingPage/champ_white_logo_1.png"
          alt=""
        />
      );
    case 2:
      return (
        <img
          style={{
            width: "1.25rem",
            height: "1.4rem",
            objectFit: "cover",
          }}
          src="/Images/landingPage/landingGameIcon.png"
          alt=""
        />
      );
    case 3:
      return (
        <img
          style={{
            width: "1.25rem",
            height: "1.4rem",
            objectFit: "cover",
          }}
          src="/Images/landingPage/landingGameIcon.png"
          alt=""
        />
      );
    case 4:
      return (
        <img
          style={{
            width: "1.3rem",
            height: "1.4rem",
            objectFit: "contain",
          }}
          src="/Images/landingPage/road-map.png"
          alt=""
        />
      );
    case 5:
      return (
        <img
          style={{
            width: "1.2rem",
            height: "1.4rem",
            objectFit: "cover",
            // filter: "grayscale(1) brightness(0.2) invert(1)",
          }}
          src="/Images/landingPage/champ_white_logo_1.png"
          alt=""
        />
      );
    default:
      return <></>;
  }
};

const LandingPageSidebar = () => {
  const [landingPageMenu, setLandingPageMenu] = useState(menus);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const { landingIsCollapsed, setLandingIsCollapsed } =
    useContext(DrawerContext);

  const handleToggle = (index) => {
    setLandingPageMenu((prevToggles) =>
      prevToggles.map((state, i) => {
        if (index === i) state.toggle = !state.toggle;
        return state;
      })
    );
  };

  return (
    <>
      {isMatch ? (
        <NavBarDrawer
          landingPageMenu={landingPageMenu}
          handleToggle={handleToggle}
          open={landingIsCollapsed}
          setOpen={setLandingIsCollapsed}
        />
      ) : (
        <List
          disablePadding
          style={{
            width: "100%",
            height: "150%",
            overflowY: "auto",
          }}
        >
          {landingPageMenu.map((e, index) => {
            if (e?.children?.length > 0) {
              return (
                <div key={index}>
                  <ListItem
                    disablePadding
                    // components={NavLink}
                    sx={{ paddingX: 1 }}
                    onClick={() => handleToggle(index)}
                  >
                    <ListItemButton
                      sx={{
                        width: "100%",
                        borderRadius: "17px",
                        paddingY: landingIsCollapsed ? 1.8 : 1.8,
                        // marginBottom: 1,
                        color: "white",
                        transition: "all 0.5s ease",
                      }}
                    >
                      {getIcons(index, landingIsCollapsed)}

                      <motion.div
                        initial={{ opacity: 0, x: -10 }} // Initial hidden state
                        animate={
                          !landingIsCollapsed
                            ? { opacity: 1, x: 0 }
                            : { opacity: 0, x: -10 }
                        } // Animate based on collapsed
                        transition={{ duration: 0.2, ease: "easeInOut" }} // Smooth transition
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 31,
                          cursor: "pointer",
                        }}
                      >
                        <ListItemText
                          sx={{
                            display: landingIsCollapsed ? "none" : "block",
                            marginLeft: 2,
                          }}
                          primary={e?.label}
                        />
                        {!landingIsCollapsed && e?.children?.length > 0 && (
                          <IconButton
                            sx={{
                              color: "#6D7992", // Set the color for the icon
                              padding: 0, // Remove extra padding for a cleaner look
                            }}
                          >
                            {e?.toggle ? (
                              <KeyboardArrowUpIcon fontSize="small" />
                            ) : (
                              <KeyboardArrowDownIcon fontSize="small" />
                            )}
                          </IconButton>
                        )}
                      </motion.div>

                      {/* <Grow in={!collapsed && e?.children?.length > 0}>
                <IoIosArrowForward fontSize={18} />
              </Grow> */}
                    </ListItemButton>
                  </ListItem>
                  <Collapse in={e?.toggle} components={NavLink}>
                    {e?.children?.map((children, childrenIndex) => (
                      <ListItem
                        key={childrenIndex}
                        disablePadding
                        components={NavLink}
                        sx={{
                          paddingX: 1,
                          // paddingLeft: collapsed ? 1 : 2
                        }}
                      >
                        <NavLink
                          to={`${children?.link}`}
                          style={({ isActive }) => ({
                            background: isActive ? "#0F2858" : "#0F103C",
                            textDecoration: "none",
                            color: isActive ? "white" : "white",
                            width: "100%",
                            borderRadius: "14px",
                            fontWeight: isActive ? 800 : 400,

                            // padding: "5px 5px",
                          })}
                        >
                          <ListItemButton
                            sx={{
                              width: "100%",
                              borderRadius: "17px",
                              paddingY: landingIsCollapsed ? 1.8 : 1.8,
                              // marginBottom: 1,
                              color:
                                childrenIndex === 1 ? "#30FF45" : "#E98816",
                              transition: "all 0.5s ease",
                            }}
                            // onClick={() => handleToggle(index)}
                          >
                            {childrenIndex === 1 ? (
                              <FaArrowTrendUp size={18} />
                            ) : (
                              <FaArrowTrendDown size={18} />
                            )}

                            <motion.div
                              initial={{ opacity: 0, x: -10 }} // Initial hidden state
                              animate={
                                !landingIsCollapsed
                                  ? { opacity: 1, x: 0 }
                                  : { opacity: 0, x: -10 }
                              } // Animate based on collapsed
                              transition={{ duration: 0.2, ease: "easeInOut" }} // Smooth transition
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 20,
                                cursor: "pointer",
                              }}
                            >
                              <ListItemText
                                sx={{
                                  display: landingIsCollapsed
                                    ? "none"
                                    : "block",
                                  marginLeft: 2,
                                }}
                                primary={children?.label}
                              />
                            </motion.div>
                          </ListItemButton>
                        </NavLink>
                      </ListItem>
                    ))}
                  </Collapse>
                </div>
              );
            }
            return (
              <ListItem
                key={index}
                disablePadding
                components={NavLink}
                sx={{ paddingX: 1 }}
              >
                <NavLink
                  to={e?.link ? `${e?.link}` : ""}
                  style={({ isActive }) => ({
                    background: isActive ? "#0F2858" : "#0F103C",
                    textDecoration: "none",
                    color: isActive ? "white" : "white",
                    width: "100%",
                    borderRadius: "14px",
                    fontWeight: isActive ? 800 : 400,

                    // padding: "5px 5px",
                  })}
                >
                  <ListItemButton
                    sx={{
                      width: "100%",
                      borderRadius: "17px",
                      paddingY: landingIsCollapsed ? 1.8 : 1.8,
                      // marginBottom: 1,
                      transition: "all 0.5s ease",
                    }}
                  >
                    {getIcons(index, landingIsCollapsed)}

                    <motion.div
                      initial={{ opacity: 0, x: -10 }} // Initial hidden state
                      animate={
                        !landingIsCollapsed
                          ? { opacity: 1, x: 0 }
                          : { opacity: 0, x: -10 }
                      } // Animate based on collapsed
                      transition={{ duration: 0.2, ease: "easeInOut" }} // Smooth transition
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 20,
                        cursor: "pointer",
                      }}
                    >
                      <ListItemText
                        sx={{
                          display: landingIsCollapsed ? "none" : "block",
                          marginLeft: 2,
                        }}
                        primary={e?.label}
                      />
                      {!landingIsCollapsed && e?.children?.length > 0 && (
                        <IoIosArrowForward fontSize={18} />
                      )}
                    </motion.div>

                    {/* <Grow in={!collapsed && e?.children?.length > 0}>
                <IoIosArrowForward fontSize={18} />
              </Grow> */}
                  </ListItemButton>
                </NavLink>
              </ListItem>
            );
          })}
        </List>
      )}
    </>
  );
};

export default LandingPageSidebar;
