import {
  Box,
  Collapse,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { RxDiscordLogo } from "react-icons/rx";
import { PiTelegramLogoLight } from "react-icons/pi";
import { RiTwitterXLine } from "react-icons/ri";
import { BiBookAlt } from "react-icons/bi";
import { NavLink, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { IoIosArrowForward } from "react-icons/io";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaArrowTrendDown } from "react-icons/fa6";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { commonData } from "../../constants/common";

const getIcons = (index, collapsed) => {
  switch (index) {
    case 0:
      return (
        <img
          style={{
            width: "0.8rem",
            height: "1.2rem",
            objectFit: "contain",
            // filter: "grayscale(1) brightness(0.2) invert(1)",
          }}
          src="/Images/landingPage/How-to-play.png"
          alt=""
        />
      );
    case 1:
      return (
        <img
          style={{
            width: "1rem",
            height: "1.2rem",
            objectFit: "cover",
            // filter: "grayscale(1) brightness(0.2) invert(1)",
          }}
          src="/Images/landingPage/champ_white_logo_1.png"
          alt=""
        />
      );
    case 2:
      return (
        <img
          style={{
            width: "1.05rem",
            height: "1.2rem",
            objectFit: "cover",
          }}
          src="/Images/landingPage/landingGameIcon.png"
          alt=""
        />
      );
    case 3:
      return (
        <img
          style={{
            width: "1.05rem",
            height: "1.2rem",
            objectFit: "cover",
          }}
          src="/Images/landingPage/landingGameIcon.png"
          alt=""
        />
      );
    case 4:
      return (
        <img
          style={{
            width: "1.1rem",
            height: "1.2rem",
            objectFit: "contain",
          }}
          src="/Images/landingPage/road-map.png"
          alt=""
        />
      );
    case 5:
      return (
        <img
          style={{
            width: "1rem",
            height: "1.2rem",
            objectFit: "cover",
            // filter: "grayscale(1) brightness(0.2) invert(1)",
          }}
          src="/Images/landingPage/champ_white_logo_1.png"
          alt=""
        />
      );
    default:
      return <></>;
  }
};

const NavBarDrawer = ({ landingPageMenu, handleToggle, open, setOpen }) => {
  // const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const collapsed = false;
  return (
    <>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            background: "#0F103C",
            height: "200%",
            pb: 2,
          }}
        >
          <Link to={"/"}>
            <Stack direction={"row"} alignItems={"center"} marginBottom={3}>
              <img
                style={{
                  width: "170px",
                  height: "60px",
                  marginTop: "15px",
                  marginLeft: "24px",
                }}
                src="/Images/chmaps-logo.png"
                alt=""
              />
            </Stack>
          </Link>

          {/* Champs amount */}
          {/* <Box
            sx={{
              display: "flex",
              background: "#0F2858",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              borderRadius: 20,
              // paddingX: "0.5rem",
              mx: "1rem",
              py: "0.5rem",
            }}
          >
            <img
              style={{
                width: "1.1rem",
                height: "1.1rem",
              }}
              src="/Images/chmp-white-logo.png"
              alt=""
            />
            <Typography color={"white"} sx={{ pr: 0.5, fontSize: 9, mt: 0.5 }}>
              1 CHAMPS =
            </Typography>
            <img
              style={{
                width: "0.9rem",
                height: "0.9rem",
              }}
              src="/Images/usdc-blue-logo.png"
              alt=""
            />
            <Typography
              color="white"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.2rem",
                ml: "0.2rem",
                fontSize: 9,
                mt: 0.5,
              }}
            >
              3346.00
              <span style={{ fontSize: 9, color: "#E2E4E9" }}>USDC</span>
            </Typography>
          </Box> */}
          <List disablePadding>
            {landingPageMenu.map((e, index) => {
              if (e?.children?.length > 0) {
                return (
                  <div key={index}>
                    <ListItem
                      disablePadding
                      // components={NavLink}
                      sx={{ paddingX: 1 }}
                      onClick={() => handleToggle(index)}
                    >
                      <ListItemButton
                        sx={{
                          width: "100%",
                          borderRadius: "17px",
                          paddingY: collapsed ? 1.8 : 1.8,
                          // marginBottom: 1,
                          color: "white",
                          transition: "all 0.5s ease",
                        }}
                      >
                        {getIcons(index, collapsed)}

                        <motion.div
                          initial={{ opacity: 0, x: -10 }} // Initial hidden state
                          animate={
                            !collapsed
                              ? { opacity: 1, x: 0 }
                              : { opacity: 0, x: -10 }
                          } // Animate based on collapsed
                          transition={{ duration: 0.2, ease: "easeInOut" }} // Smooth transition
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 31,
                            cursor: "pointer",
                          }}
                        >
                          <ListItemText
                            sx={{
                              display: collapsed ? "none" : "block",
                              marginLeft: 2,
                            }}
                            primary={e?.label}
                            primaryTypographyProps={{
                              fontSize: 14,
                            }}
                          />
                          {!collapsed && e?.children?.length > 0 && (
                            <IconButton
                              sx={{
                                color: "#6D7992", // Set the color for the icon
                                padding: 0, // Remove extra padding for a cleaner look
                              }}
                            >
                              {e?.toggle ? (
                                <KeyboardArrowUpIcon fontSize="small" />
                              ) : (
                                <KeyboardArrowDownIcon fontSize="small" />
                              )}
                            </IconButton>
                          )}
                        </motion.div>

                        {/* <Grow in={!collapsed && e?.children?.length > 0}>
                <IoIosArrowForward fontSize={18} />
              </Grow> */}
                      </ListItemButton>
                    </ListItem>
                    <Collapse in={e?.toggle} components={NavLink}>
                      {e?.children?.map((children, childrenIndex) => (
                        <ListItem
                          key={childrenIndex}
                          disablePadding
                          components={NavLink}
                          sx={{ paddingX: 1, paddingLeft: collapsed ? 1 : 2 }}
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <NavLink
                            to={`${children?.link}`}
                            style={({ isActive }) => ({
                              background: isActive ? "#0F2858" : "#0F103C",
                              textDecoration: "none",
                              color: isActive ? "white" : "white",
                              width: "100%",
                              borderRadius: "14px",
                              fontWeight: isActive ? 800 : 400,

                              // padding: "5px 5px",
                            })}
                          >
                            <ListItemButton
                              sx={{
                                width: "100%",
                                borderRadius: "17px",
                                paddingY: collapsed ? 1.8 : 1.8,
                                // marginBottom: 1,
                                color:
                                  childrenIndex === 1 ? "#30FF45" : "#E98816",
                                transition: "all 0.5s ease",
                              }}
                              // onClick={() => handleToggle(index)}
                            >
                              {childrenIndex === 1 ? (
                                <FaArrowTrendUp size={18} />
                              ) : (
                                <FaArrowTrendDown size={18} />
                              )}

                              <motion.div
                                initial={{ opacity: 0, x: -10 }} // Initial hidden state
                                animate={
                                  !collapsed
                                    ? { opacity: 1, x: 0 }
                                    : { opacity: 0, x: -10 }
                                } // Animate based on collapsed
                                transition={{
                                  duration: 0.2,
                                  ease: "easeInOut",
                                }} // Smooth transition
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 20,
                                  cursor: "pointer",
                                }}
                              >
                                <ListItemText
                                  sx={{
                                    display: collapsed ? "none" : "block",
                                    marginLeft: 2,
                                  }}
                                  primary={children?.label}
                                  primaryTypographyProps={{
                                    fontSize: 14,
                                  }}
                                />
                              </motion.div>
                            </ListItemButton>
                          </NavLink>
                        </ListItem>
                      ))}
                    </Collapse>
                  </div>
                );
              }
              return (
                <ListItem
                  key={index}
                  disablePadding
                  components={NavLink}
                  sx={{ paddingX: 1 }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <NavLink
                    to={`${e?.link}`}
                    style={({ isActive }) => ({
                      background: isActive ? "#0F2858" : "#0F103C",
                      textDecoration: "none",
                      color: isActive ? "white" : "white",
                      width: "100%",
                      borderRadius: "14px",
                      fontWeight: isActive ? 800 : 400,

                      // padding: "5px 5px",
                    })}
                  >
                    <ListItemButton
                      sx={{
                        width: "100%",
                        borderRadius: "17px",
                        paddingY: collapsed ? 1.8 : 1.8,
                        // marginBottom: 1,
                        transition: "all 0.5s ease",
                      }}
                    >
                      {getIcons(index, collapsed)}

                      <motion.div
                        initial={{ opacity: 0, x: -10 }} // Initial hidden state
                        animate={
                          !collapsed
                            ? { opacity: 1, x: 0 }
                            : { opacity: 0, x: -10 }
                        } // Animate based on collapsed
                        transition={{ duration: 0.2, ease: "easeInOut" }} // Smooth transition
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 20,
                          cursor: "pointer",
                        }}
                      >
                        <ListItemText
                          sx={{
                            display: collapsed ? "none" : "block",
                            marginLeft: 2,
                          }}
                          primary={e?.label}
                          primaryTypographyProps={{
                            fontSize: 14,
                          }}
                        />
                        {!collapsed && e?.children?.length > 0 && (
                          <IoIosArrowForward fontSize={18} />
                        )}
                      </motion.div>

                      {/* <Grow in={!collapsed && e?.children?.length > 0}>
                <IoIosArrowForward fontSize={18} />
              </Grow> */}
                    </ListItemButton>
                  </NavLink>
                </ListItem>
              );
            })}
          </List>

          <List sx={{ marginTop: 3 }}>
            <Grid
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              marginTop={1}
              gap={2}
              sx={{
                marginRight: { md: 3 },
              }}
            >
              <a
                href="https://cryptochamps-io.gitbook.io/cryptochamps.io/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  padding={0.8}
                  sx={{
                    borderRadius: "50px",
                    border: 1,
                    borderColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      color: "#11B1F4",
                      background: "transparent",
                      borderColor: "#11B1F4",
                    },
                    cursor: "pointer",
                  }}
                >
                  <BiBookAlt size={18} />
                </Box>
              </a>

              <a
                href="https://twitter.com/cryptochamps_io?s=21"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  padding={0.8}
                  sx={{
                    borderRadius: "50px",
                    border: 1,
                    borderColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      color: "#11B1F4",
                      background: "transparent",
                      borderColor: "#11B1F4",
                    },
                    cursor: "pointer",
                  }}
                >
                  <RiTwitterXLine size={18} />
                </Box>
              </a>

              <a
                // href="https://discord.gg/usquyPnE"
                // href="https://discord.gg/8Pm34WbS"
                // href="https://discord.gg/5FXMgARh"
                href={commonData?.discord}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  padding={0.8}
                  sx={{
                    borderRadius: "50px",
                    border: 1,
                    borderColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      color: "#11B1F4",
                      background: "transparent",
                      borderColor: "#11B1F4",
                    },
                    cursor: "pointer",
                  }}
                >
                  <RxDiscordLogo size={18} />
                </Box>
              </a>

              <a
                // href="https://t.me/CryptoChampsIO"
                href="https://t.me/CryptoChampsx"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {" "}
                <Box
                  padding={0.8}
                  sx={{
                    borderRadius: "50px",
                    border: 1,
                    borderColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      color: "#11B1F4",
                      background: "transparent",
                      borderColor: "#11B1F4",
                    },
                    cursor: "pointer",
                  }}
                >
                  <PiTelegramLogoLight size={18} />
                </Box>
              </a>
            </Grid>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default NavBarDrawer;
