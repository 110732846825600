import { Box } from "@mui/material";

const LandingAmbassadorBanner = () => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pt: { md: 10, xs: 5 },
        pb: 3,
      }}
    >
      <a
        // to={"/"}
        href="#FAQ_SECTION"
        style={{
          // width: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="/Images/landingPage/ambassador.png"
          style={{
            width: "100%",
          }}
          loading="lazy"
        />
      </a>

      {/* Background Video */}
      {/* <video
        autoPlay
        loop
        muted
        style={{
          width: "30%",
          height: "30%",
          objectFit: "cover", // Ensures the video covers the entire container
          position: "absolute",
          left: "3%",
          top: "30%",
        }}
      >
        <source src={"/video/bull-video.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
    </Box>
  );
};

export default LandingAmbassadorBanner;
