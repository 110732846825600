import { Box, Typography } from "@mui/material";
import { appBarZindex } from "./LandingHeader";
import LandingButton from "./LandingButton";
import { useNavigate } from "react-router-dom";

export const LandingTopVideo = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "auto",
        overflow: "hidden",
        // borderRadius: "20px 0 20px 0",
      }}
    >
      {/* Background Video */}
      <video
        autoPlay
        loop
        muted
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover", // Ensures the video covers the entire container
        }}
      >
        {/* <source src={"/video/landingTopVideo.mp4"} type="video/mp4" /> */}
        <source
          src="https://the420link1.s3.amazonaws.com/LandingPage/d2ab51e3-3e3d-42a0-a453-96b0c42d97b8_Animation-4mb.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      <div
        style={{
          width: "80%",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "100%",
            // width: "100%",
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: {
              xs: "start",
              md: "space-between",
            },
            alignItems: {
              xs: "center",
              md: "start",
            },
            alignContent: {
              md: "start",
            },
            zIndex: 2,
            color: "#FFFFFF",
            background: "rgba(0, 0, 0, 0.2)", // Optional: Adds a dark overlay for better text visibility
            px: {
              xs: 2,
              md: 5,
            },
            py: {
              xs: 2,
              md: 5,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column", // Responsive flex direction
              alignItems: {
                xs: "center",
                md: "start",
              },
              width: "80%",
            }}
          >
            <Typography
              variant="h3"
              fontSize={{ md: "3.5rem", sm: "2rem", xs: "1rem" }}
              fontFamily={'"Bebas Neue", system-ui'}
              fontWeight={800}
              sx={{ textAlign: "center", mb: 1 }}
            >
              Welcome to the world of{" "}
            </Typography>
            <Typography
              variant="h3"
              fontSize={{ md: "3.5rem", sm: "2rem", xs: "1rem" }}
              fontFamily={'"Bebas Neue", system-ui'}
              fontWeight={800}
              sx={{ color: "#0EA1EE", textAlign: "center", mb: 2 }}
            >
              CHAMPS
            </Typography>
          </Box>

          <div
            style={{
              display: "flex",
            }}
          >
            <LandingButton
              text={"Get Started"}
              onClick={() => navigate("/signup")}
            />
          </div>
        </Box>
      </div>
      {/* Overlay Content */}
    </Box>
  );
};
