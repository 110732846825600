export class commonData {
  static USDC_CONTRACT_ADDRESS = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";
  static usdcDecimals = 6; //TestNet 18

  static tokenDecimal = 3; // for token decimal points

  static allowanceAmount = 1000000000;

  static discord = "https://discord.gg/aCAxTKUArF";
}
