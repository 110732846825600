import React from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

export const LandingMiddleVideo = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const videoRef = React.useRef(null);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const togglePlayPause = () => {
    console.log(isPlaying);
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        marginBottom: 10,
        marginTop: 5,
      }}
      onClick={togglePlayPause}
    >
      {/* Background Video */}
      <video
        ref={videoRef}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover", // Ensures the video covers the entire container
        }}
        controls={false}
        onClick={togglePlayPause}
      >
        {/* <source src={"/video/landingMiddleVideo.mp4"} type="video/mp4" /> */}
        <source src={"https://the420link1.s3.amazonaws.com/LandingPage/4888dbb2-7831-4c32-97d2-e25d3d01f0b4_video_2024-11-28_12-08-31.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* OVERLAY BUTTON */}
      {!isPlaying && (
        //OVERLAY COLOR
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "#00000094",
            zIndex: 1,
          }}
        >
          {/* ALIGN CENTER */}
          <Box
            sx={{
              position: "absolute",
              top: { xs: "30%", md: "35%" },
              left: "50%",
              zIndex: 2,
              background:
                "linear-gradient(109.59deg, #11B1F4 -32.74%, #085A7D 127.35%)",
              borderRadius: "100%",
              padding: { xs: 0.3, md: 1 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            {/* PLAY BUTTON */}
            <PlayArrowIcon
              fontSize={isMatch ? "small" : "large"}
              sx={{ color: "white" }}
            />
          </Box>

          {/* Text AND ALIGN */}
          <div
            style={{
              position: "absolute",
              top: "55%",
              // left: "50%",
              // right: "50%",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography
              variant={isMatch ? "h9" : "h4"}
              sx={{ color: "white", fontWeight: "bold" }}
            >
              HOW DOES CRYPTO CHAMPS WORK
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};
